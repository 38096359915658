import * as React from "react"

export default class Search extends React.Component {
  state = {
    keywords: "",
    hasErrors: false
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    if (this.state.keywords.length < 3) {
      event.preventDefault()
      this.setState({
        hasErrors: true
      })
      setTimeout(function () { 
        this.setState({
          hasErrors: false
        })
      }.bind(this), 4000);
      return false
    }
    return true
  }

  render () {
    const showHideClass = this.state.hasErrors ? '' : 'hide'

    return (
      <div>
        <form onSubmit={this.handleSubmit} method="get" action="/busqueda" className="input-group-over d-block mb-2">
          <div className="form-label-group mb-3">
            <input
              required placeholder="Buscar..."
              id="blog_search"
              name="keywords"
              type="text"
              className="form-control"
              value={this.state.keywords}
              onChange={this.handleInputChange}
            />
            <label htmlFor="blog_search">Buscar...</label>
          </div>
          <button type="submit" className="btn fs--12">
            <i className="fi fi-search m-0"></i>
          </button>
        </form>
        <div className={"bs-validate-info alert alert-danger fs--11 " + showHideClass} data-error-alert-delay="4000">
          <i className="fi fi-circle-spin fi-spin float-start"></i>
          Introduce al menos tres caracteres!
        </div>
      </div>
    )
  } 
}