import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => {

  return (
    <footer id="footer" className="footer-dark">
      <div className="container">
        <div className="row">

          <div className="bg-distinct col-12 col-md-6 col-lg-4 text-center p-0 py-5">
            
            <div className="footer-svg-shape position-absolute absolute-top z-index-2 mt--n70 w-100 overflow-hidden pt--5">
              <svg viewBox="0 0 1440 28" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                <path d="M0 28H1440C1440 28 1154 3.21961e-10 720 1.30487e-09C286 2.28777e-09 0 28 0 28Z"></path>
              </svg>
            </div>

            <p id="btnAriaFooterFormTitle" className="text-muted">
              Síguenos en nuestras redes
            </p>

            <div className="mt--25">
              <a href="https://www.facebook.com/EstoApestaFans/" target="_blank" className="btn btn-sm btn-facebook transition-hover-top mb-2 rounded-circle" rel="noreferrer" aria-label="facebook page">
                <i className="fi fi-social-facebook"></i>
              </a>
              <a href="https://twitter.com/estoapesta" target="_blank" className="btn btn-sm btn-twitter transition-hover-top mb-2 rounded-circle" rel="noreferrer" aria-label="twitter page">
                <i className="fi fi-social-twitter"></i>
              </a>
              <a href="#!" className="btn btn-sm btn-rss transition-hover-top mb-2 rounded-circle" rel="noopener" aria-label="youtube page">
                <i className="fi fi-social-rss"></i>
              </a>
            </div>

            <div className="mt--40">
              <Link to="/">
                <StaticImage
                  className="center-image transition-hover-top transition-all-ease-250"
                  layout="fixed"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  src="../images/esto-icon.png"
                  width={90}
                  height={90}
                  quality={95}
                  alt="Esto Apesta"
                />
              </Link>
            </div>

          </div>

          <div className="col-12 col-md-6 col-lg-8 py-5 fs--15 text-center-xs">

            <h3 className="fs--15 text-muted">ACERCA DE</h3>
            <div className="mt--30">
              <p className="text-white">
                ¿Qué es Esto Apesta?
              </p>
              <p className="text-muted text-justify">
                Bienvenido a <Link to="/">Esto Apesta</Link>, el blog personal de Chris Pérez un supuesto graduado de sistemas que gusta de la tecnología, los videojuegos, el cine, la música y por supuesto adora los zombies y la cerveza.
              </p>
            </div>
            <div className="mt--30">
              <p className="text-white">
                ¿Qué puedes encontar en este blog?
              </p>
              <p className="text-muted text-justify">
                Normalmente publicamos noticias relacionadas con la cultura geek, el cine y videojuegos, pero nos enfocamos en hacerlo con mucho humor para lograr arrancarte una sonrisa cada vez que nos visitas.
              </p>
            </div>

          </div>
        </div>
      </div>
      <div className="bg-distinct py-3 clearfix">
        <div className="container clearfix font-weight-light text-center-xs">
          <div className="fs--14 py-2 float-start float-none-xs m-0-xs">
            &copy; Esto Apesta | 2010 - {new Date().getFullYear()}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer