import React, {useState} from "react"
import Search from "./search"
import { Link } from "gatsby"

const Sidebar = ({location}) => {
  const categorias = [
    { name: 'Videojuegos' , link: '/videojuegos' },
    { name: 'Películas' , link: '/peliculas' },
    { name: 'Series' , link: '/series' },
    { name: 'Entretenimiento' , link: '/entretenimiento' },
    { name: 'Geek' , link: '/geek' },
  ]

  const [showCategories, toggleShowCategories] = useState(false);

  return (
    <div className="col-lg-3 order-1 order-lg-2 mb-5">
      <Search />

      <nav className="nav-deep nav-deep-light mb-2">
        {/* <!-- mobile only --> */}
        <button onClick={() => toggleShowCategories(value => !value)} className="clearfix btn btn-toggle btn-sm btn-block text-align-left shadow-md border rounded mb-1 d-block d-lg-none" data-target="#nav_responsive" data-toggle-container-class="d-none d-sm-block bg-white shadow-md border animate-fadein rounded p-3">
          <span className="group-icon px-2 py-2 float-start">
            <i className="fi fi-bars-2" style={{ display: (showCategories ? 'none' : 'inline-block') }}></i>
            <i className="fi fi-close" style={{ display: (showCategories ? 'inline-block' : 'none') }}></i>
          </span>

          <span className="h5 py-2 m-0 float-start">
            Categorías
          </span>
        </button>

        <h3 className="h5 pt-3 pb-3 m-0 d-none d-lg-block">
          Categorías
        </h3>

        <ul id="nav_responsive" className={"nav flex-column d-lg-block bg-white " + (showCategories ? "shadow-md border animate-fadein rounded p-3" : "d-none")}>
          {categorias.map(categoria => {
            const urlSections = location.pathname.split('/')
            const classActive = ('/' + urlSections[1]) === categoria.link ? 'active' : ''
            return (
              <li key={categoria.name} className={"nav-item " + classActive}>
                <Link className="nav-link px-0" to={categoria.link}>
                  <i className="fi fi-arrow-end m-0 fs--12"></i>
                  <span className="px-2 d-inline-block">
                    {categoria.name}
                  </span>
                </Link>
              </li>
            )
          })}
        </ul>
      </nav>
    </div>
  )
}

export default Sidebar