import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import logo from "../images/logo-esto.png"
import scrollTo from 'gatsby-plugin-smoothscroll';

const Header = ({title, location}) => {

  const [showMobileMenu, toggleShowMobileMenu] = useState(false);

  const closeMobileMenuHome = () => {
    if (location.pathname === '/' && showMobileMenu) {
      toggleShowMobileMenu(value => !value)
    }
  }

  const closeMobileMenu = () => {
    if (showMobileMenu) {
      toggleShowMobileMenu(value => !value)
    }
  }

  return (
    <header id="header" className="main-nav shadow-xs bg-gradient-dark">
      <div className="container position-relative">
        <nav className="navbar navbar-expand-lg justify-content-lg-between justify-content-md-inherit navbar-dark">
          <div className="align-items-start">
            <button onClick={() => toggleShowMobileMenu(value => !value)} className={"navbar-toggler " + (showMobileMenu ? '' : 'collapsed')} type="button" data-toggle="collapse" data-target="#navbarMainNav" aria-controls="navbarMainNav" aria-expanded="false" aria-label="Toggle navigation">
              <svg width="25" viewBox="0 0 20 20">
                <path fill="#fff" d="M 19.9876 1.998 L -0.0108 1.998 L -0.0108 -0.0019 L 19.9876 -0.0019 L 19.9876 1.998 Z"></path>
                <path fill="#fff" d="M 19.9876 7.9979 L -0.0108 7.9979 L -0.0108 5.9979 L 19.9876 5.9979 L 19.9876 7.9979 Z"></path>
                <path fill="#fff" d="M 19.9876 13.9977 L -0.0108 13.9977 L -0.0108 11.9978 L 19.9876 11.9978 L 19.9876 13.9977 Z"></path>
                <path fill="#fff" d="M 19.9876 19.9976 L -0.0108 19.9976 L -0.0108 17.9976 L 19.9876 17.9976 L 19.9876 19.9976 Z"></path>
              </svg>
            </button>
            <Link className="navbar-brand" to="/">
              {/* <StaticImage
                layout="fixed"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../images/logo-esto.png"
                width={180}
                height={70}
                quality={95}
                alt={title}
              /> */}
              <img src={logo} width="auto" height="80px" alt={title} />
            </Link>
          </div>
          <div className={" collapse navbar-collapse navbar-animate-fadein justify-content-end " + (showMobileMenu ? 'show' : '')} id="navbarMainNav">
            {/* <!-- MOBILE MENU NAVBAR --> */}
            <div className="navbar-xs d-none bg-gradient-dark">
              <button onClick={() => toggleShowMobileMenu(value => !value)} className="navbar-toggler pt-0" type="button" data-toggle="collapse" data-target="#navbarMainNav" aria-controls="navbarMainNav" aria-expanded="false" aria-label="Toggle navigation">
                <svg width="20" viewBox="0 0 20 20">
                  <path fill="#fff" d="M 20.7895 0.977 L 19.3752 -0.4364 L 10.081 8.8522 L 0.7869 -0.4364 L -0.6274 0.977 L 8.6668 10.2656 L -0.6274 19.5542 L 0.7869 20.9676 L 10.081 11.679 L 19.3752 20.9676 L 20.7895 19.5542 L 11.4953 10.2656 L 20.7895 0.977 Z"></path>
                </svg>
              </button>

              <Link className="navbar-brand" to="/">
                <StaticImage
                  layout="fixed"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  src="../images/logo-esto.png"
                  width={180}
                  height={70}
                  quality={95}
                  alt={title}
                />
              </Link>
            </div>
            {/* <!-- /MOBILE MENU NAVBAR --> */}

            <ul className="navbar-nav ">
              <li className={"nav-item " + (showMobileMenu ? 'dropdown-item' : '')}>
                <Link className="nav-link" onClick={() => closeMobileMenuHome()} to="/">
                  Inicio
                </Link>
              </li>
              <li className={"nav-item " + (showMobileMenu ? 'dropdown-item' : '')}>
                <a href="#!" onClick={() => {closeMobileMenu(); scrollTo('#footer')}} className="nav-link">
                  Acerca de
                </a>
              </li>

              <li className="nav-item d-block d-sm-none text-center mt-5 mb-4">

                <h3 className="h6 text-muted">Síguenos</h3>

                <a href="https://www.facebook.com/EstoApestaFans/" target="_blank" className="btn btn-sm btn-facebook transition-hover-top mb-2 rounded-circle text-white" rel="noreferrer">
                  <i className="fi fi-social-facebook"></i>
                </a>
                <a href="https://twitter.com/estoapesta" target="_blank" className="btn btn-sm btn-twitter transition-hover-top mb-2 rounded-circle text-white" rel="noreferrer">
                  <i className="fi fi-social-twitter"></i>
                </a>
                <a href="#!" className="btn btn-sm btn-rss transition-hover-top mb-2 rounded-circle text-white" rel="noopener">
                  <i className="fi fi-social-rss"></i>
                </a>

              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header