import * as React from "react"
// import { Link } from "gatsby"
// import logo from "../images/logo-esto.png"
import Header from "./header"
import Sidebar from "./sidebar"
import Footer from "./footer"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div id="wrapper" data-is-root-path={isRootPath}>
      <Header title={title} location={location} />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-9 order-2 order-lg-1">
              {children}
            </div>
            <Sidebar location={location} />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Layout
